import * as React from "react";
import { graphql } from "gatsby";
import { getSrc } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Navigation from "../components/navigation";
import Header from "../components/header";
import Content from "../components/content";
import Tabs from "../components/tabs";

const Page = ({ data }) => {
  const pages = data.pages.nodes;
  const { frontmatter, body } = data.page;
  const { title, description, grading, tabs, images } = frontmatter;
  const subpageTitle = tabs?.find(({ isActive }) => isActive).title;
  const seoTitle = tabs ? `${title} – ${subpageTitle}` : title;

  return (
    <Layout>
      <Seo
        title={seoTitle.replace(/(<([^>]+)>)/gi, ``)}
        image={getSrc(images[0])}
      />
      <Header title={title} intro={description} grading={grading} />
      <Navigation pages={pages} />
      <Content>
        {tabs && <Tabs tabs={tabs} />}
        <article>
          <MDXRenderer grading={grading} images={images}>
            {body}
          </MDXRenderer>
        </article>
      </Content>
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query ($locale: String!, $permalink: String!) {
    pages: allMdx(
      filter: {
        fields: { locale: { eq: $locale } }
        frontmatter: { order: { ne: null } }
      }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      nodes {
        id
        frontmatter {
          title
          permalink
          icon
        }
      }
    }
    page: mdx(
      frontmatter: { permalink: { eq: $permalink } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        description
        grading {
          impact {
            level
            description
          }
          difficulty {
            level
            description
          }
        }
        tabs {
          title
          permalink
          isActive
        }
        images {
          childImageSharp {
            gatsbyImageData(width: 1280, quality: 80)
          }
        }
      }
      body
    }
  }
`;
