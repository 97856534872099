import * as React from "react";
import { useIntl } from "react-intl";
import LocalizedLink from "../components/localized-link";

const Tabs = ({ tabs }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="tabs">
      <nav aria-label={formatMessage({ id: `subpages` })}>
        <ul>
          {tabs.map(({ permalink, isActive, title }) => (
            <li key={permalink}>
              <LocalizedLink
                to={`/${permalink}`}
                animated={false}
                aria-current={isActive ? `page` : null}
              >
                {title}
              </LocalizedLink>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Tabs;
